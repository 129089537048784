.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.search-bar {
  margin: 20px 0;
  padding: 10px;
  width: 80%;
}

.user-list {
  width: 80%;
  margin: 0 auto;
}

.user-accordion {
  border: 1px solid #ddd;
  margin: 10px 0;
  padding: 10px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-body {
  padding: 10px;
  border-top: 1px solid #ddd;
}

.edit-user-form label {
  display: block;
  margin: 10px 0;
}

.edit-user-form input,
.edit-user-form select,
.edit-user-form textarea {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
}

.delete-dialog {
  padding: 20px;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
}

.delete-dialog p {
  margin: 0 0 20px;
}
